<template>
  <!-- banner部分 -->
  <div class="swiper-banner" ref="mySwiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(m,index) in index_banner" v-bind:key="index">
        <router-link :to="m.url">
          <img :src="m.img" :alt="m.title" :title="m.title"/>
        </router-link>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
  <!-- 快捷导航 -->
  <div class="statistical-section quick-nav-section">
    <div class="gk-container gk-clear">
      <ul>
        <li class="gk-col-lg2" v-for="(m,index) in nav_second" v-bind:key="index">
          <router-link :to="m.url">
            <img :src="m.img" :alt="m.name" :title="m.name">
            <span>{{ m.name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <!--解决方案、产品技术等 选项卡部分-->
  <div class="container-tabs">
    <div class="gk-container">
      <div class="tabs-nav">
        <ul>
          <li><a href="javascript:;" :class="{active:this.cid==0}" @click="typeChange(0)">热门</a></li>
          <li v-for="(m,index) in type_data" v-bind:key="index">
            <a href="javascript:;" :class="{active:this.cid==m.id}" @click="typeChange(m.id)">{{ m.name }}</a>
          </li>
        </ul>
      </div>
      <div class="tabs-box-cont">
        <div class="tabs-box" v-loading="loading" element-loading-background="rgba(245,245,245,1)">
          <ul>
            <li v-for="(m,index) in data_list" v-bind:key="index">
              <div class="pic">
                <router-link :to="'/detail/'+m.id">
                  <img :src="m.img" :alt="m.title">
                </router-link>
              </div>
              <h3><router-link :to="'/detail/'+m.id">{{ m.title }}</router-link></h3>
              <div class="tags">
                <span v-for="(item,index) in m.tags" :key="index">{{ item}}</span>
              </div>
              <div class="numMod">
                <div class="item">
                  <img src="static/iconImg/icon-view.png" alt=""><span>{{ m.view }}</span>
                </div>
                <div class="item">
                  <img src="static/iconImg/icon-fav.png" alt=""><span>{{ m.favorite }}</span>
                </div>
                <div class="item">
                  <img src="static/iconImg/icon-download.png" alt=""><span>{{ m.download }}</span>
                </div>
              </div>
              <div class="authorMod">
                <img class="tx" :src="m.user.avatar" alt="">
                <span>{{ m.user.nickname }}</span>
                <img v-if="m.user.is_vid===1" class="mark" src="static/iconImg/icon-mark-personal.png" alt="">
                <img v-if="m.user.is_vid===2" class="mark" src="static/iconImg/icon-mark-enterprise.png" alt="">
              </div>
              
              <!-- <div class="info">
                <div class="author"><img :src="m.user.avatar" alt=""><span>{{ m.user.nickname }}</span></div>
                <div class="viewer"><img src="static/iconImg/icon-view.png" alt=""><span>{{ m.view }}</span></div>
              </div> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>


</template>
<script>
import sys from "@/api/system";
import article from "@/api/article";
import "swiper/swiper-bundle.min.css";
import Swiper, {Navigation, Pagination, Autoplay} from "swiper";

Swiper.use([Autoplay, Navigation, Pagination]);

export default {
  name: "index",
  components: {},
  data() {
    return {
      index_banner: [],
      nav_second: [],
      type_data: [],
      data_list: [],
      cid: 0,
      loading: false,
    }
  },
  created() {
    this.getAdList();
    this.getNavSecondList();
    this.getCategoryNameList();
    this.getIndexList();
  },
  mounted() {

  },
  methods: {
    //轮播图 banner
    getAdList() {
      sys.getAdList({
        position: 'index_banner'
      }).then(res => {
        if (res) {
          this.index_banner = res.data;
        } else {
          console.log(res);
        }
      })
    },
    //导航调取
    getNavSecondList() {
      sys.getNavSecondList({
        type: 'pc'
      }).then(res => {
        if (res) {
          this.nav_second = res.data;
        } else {
          console.log(res);
        }
      })
    },
    //首页 分类选项卡
    getCategoryNameList() {
      article.getCategoryNameList({
        ident: 'type'
      }).then(res => {
        if (res) {
          this.type_data = res.data;
        }
      })
    },
    //切换 类型 选项卡
    typeChange(cid) {
      this.cid = cid;
      this.getIndexList()
    },
    //调取 首页数据
    getIndexList() {
      this.loading = true
      article.getListRows({
        cid: this.cid
      }).then(res => {
        if (res) {
          this.loading = false
          var arr = res.data.map(item=>{
            item.tags = item.tags.split('；');
            return item
          })
          this.data_list = arr;
        } else {
          console.log(res);
        }
      })
    },
    // 轮播图效果
    banner() {
      new Swiper(this.$refs.mySwiper, {
        direction: 'horizontal',
        loop: true,
        loopAdditionalSlides: 1,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        observeParents: false,
        observer: true,
        speed: 1000,
        centeredSlides: true,
        slidesPerView: 1.6,
        spaceBetween: 20,
      })
    }
  },
  watch: {
    index_banner: {
      handler: function () {
        this.$nextTick(() => {
          this.banner()
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .numMod{display: flex;align-items: center;margin-bottom:8px;
    .item{display: flex;align-items: center;margin-right:14px;
      img{margin-right: 7px;}
      span{height:12px; font-size: 12px;color:#666;font-family: "DIN";font-weight: 700;}
    }        
  }
  .authorMod{display: flex;align-items: center;
    .tx{width: 22px;height: 22px;margin-right:5px;}
    span{font-size: 14px;color:#333;margin-right:5px;}
    .mark{width: 14px;height: 14px;}
  }
</style>